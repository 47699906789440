import { HEADER_PHOTO, HEADER_INFO, RESET_HEADER_INFO } from './Types';

export const getHeaderPhoto = (photo) => {
  return {
    type: HEADER_PHOTO,
    payload: photo,
  };
};

export const getHeaderInfo = (text) => {
  return {
    type: HEADER_INFO,
    payload: text,
  };
};

export const resetHeaderInfo = () => {
  return {
    type: RESET_HEADER_INFO,
  };
};
