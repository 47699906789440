import LSDImage from '../static/images/LSD-cover.jpg';
import Camp2018 from './images/Camp2018.jpg';
import Cost2Drive from './images/cost2drive.jpg';
import FunParkCover from './images/fun-park-cover.jpg';
import JukeCover from './images/juke-cover.jpg';

const data = [
  {
    id: 1,
    tags: ['#reactjs', '#frontend'],
    info: {
      name: 'JUKE',
      external: 'https://juke.nl',
      path: '/',
      photo: JukeCover,
      headerText:
        '<em>Project,</em>\r\nJUKE,\r\n<span>\r\n#reactjs #frontend</span>',
      linkUrl: 'https://juke.nl',
      linkText: 'juke.nl',
      primaryColor: '247, 192, 51',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    },
  },
  {
    id: 2,
    tags: ['#reactnative', '#ios', '#android', '#wordpress', '#ux'],
    info: {
      name: 'The Fun Park 2019',
      path: '/project/the-fun-park',
      photo: FunParkCover,
      headerText:
        '<em>Project,</em>\r\nThe Fun Park 2019,\r\n<span>\r\n#reactnative #ios #android #wordpress #ux</span>',
      linkUrl: 'https://thefunpark.org',
      linkText: 'thefunpark.org',
      primaryColor: '255,204,0',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    },
  },
  {
    id: 3,
    tags: ['#frontend', '#wordpress', '#ux'],
    info: {
      name: 'Light System Design',
      path: '/project/light-system-design',
      photo: LSDImage,
      headerText:
        '<em>Project,</em>\r\nLight System Desing,\r\n<span>\r\n#frontend #wordpress #ux</span>',
      linkUrl: 'https://www.lsdesign.rs',
      linkText: 'lsdesign.rs',
      primaryColor: '137,22,22',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    },
  },
  {
    id: 4,
    tags: ['#reactnative', '#ios', '#android'],
    info: {
      name: 'Camp Wonderpolis 2018',
      path: '/project/camp-wonderpolis-2018',
      photo: Camp2018,
      headerText:
        '<em>Project,</em>\r\nCamp Wonderpolis 2018,\r\n<span>\r\n#reactnative #ios #android</span>',
      linkUrl: '',
      linkText: 'camp2018.wonderopolis.org',
      primaryColor: '147,88,171',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    },
  },
  {
    id: 5,
    tags: ['#frontend', '#reactjs', '#wordpress', '#ux'],
    info: {
      name: 'Cost 2 Drive',
      path: '/project/cost-2-drive',
      photo: Cost2Drive,
      headerText:
        '<em>Project,</em>\r\nCost 2 Drive,\r\n<span>\r\n#frontend #reactjs #wordpress #ux</span>',
      linkUrl: '',
      linkText: '',
      primaryColor: '55,177,228',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    },
  },
];

export default data;
