import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

class ProjectTopDecor extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          this.props.location.pathname !== '/'
            ? 'project-decor-top active'
            : 'project-decor-top'
        }
        style={{
          backgroundColor: `rgb(${this.props.header.info.secondaryColor})`,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    header: state.header,
  };
};

export default connect(mapStateToProps)(ProjectTopDecor);
