import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHeaderPhoto, getHeaderInfo } from 'actions';
import TopBarBack from 'components/TopBarBack';

class About extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getHeaderPhoto('/nemanja-smiljanic-portfolio.jpg');
    this.props.getHeaderInfo({
      headerText: '',
      linkUrl: '',
      primaryColor: '224,149,89',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    });
  }

  render() {
    return (
      <div>
        <TopBarBack />
        <div className="projects-info">
          <section>
            <div className="desc-section first">
              <h1>
                Hello <span className="wave">👋</span>
                <br />
                <span style={{ fontWeight: 'normal' }}>My name is </span>
                <b>Nemanja Smiljanic</b>
                <span style={{ fontWeight: 'normal' }}>.</span>
              </h1>
              <p>
                I'm a passionate web & mobile developer who has a good eye for
                aesthetic, insights that enrich user experience and knowledge to
                make the technical side happen.
                <br />
                <br />
                I have worked in various teams and strongly believe that good
                team harmony can do wonders and I always strive to it. I love
                collaborating with those around me in order to better understand
                people, ideas, processes and technologies, and to form the most
                efficient and maintainable solutions.
                <br />
                <br />
                Also, I'm not a stranger, and enjoy being a single "developer"
                that can be involved <u>from start</u> in the development of a
                product, from sitting down with the client and harvesting the
                idea, brainstorming and making UX prototypes,{' '}
                <u>up to delivering</u> a finished product with the follow-up
                maintenance.
              </p>
            </div>
            <h2>SKILLS</h2>
            <div className="desc-section">
              <ul className="desc-timeline">
                <li>
                  <b>#frontend</b>
                  <p>HTML, CSS, Scss, Bootstrap 4, Jquery, Animations</p>
                </li>
                <li>
                  <b>#reactjs</b>
                  <p>
                    JavaScript, React JS, Redux, REST, GraphQL, Jest, Styled
                    Components, JSS
                  </p>
                </li>
                <li>
                  <b>#reactnative</b>
                  <p>React Native</p>
                </li>
                <li>
                  <b>#backend</b>
                  <p>NodeJS, MongoDB + Mongoose, MySQL</p>
                </li>
                <li>
                  <b>#CMS</b>
                  <p>Contentful, Wordpress + custom themes, WooCommerce</p>
                </li>
                <li>
                  <b>#other</b>
                  <p>
                    Git, npm, webpack, gulp, Immutable JS, SEO, UX, Photoshop,
                    Illustrator, Figma, Atomic design, SCRUM, IOT
                  </p>
                </li>
              </ul>
              <p>
                Due to my practical personality, I am not afraid to take a
                challenge and learn on the go 🥇
              </p>
            </div>
            <h2>FREE TIME</h2>
            <div className="desc-section">
              <p>
                When I’m not in the office, one of the places you can find me is
                on the shooting range.
              </p>
            </div>
            <div
              className="desc-promo-background about"
              style={{
                backgroundImage: 'url(/nemanja-smiljanic-porpetto.jpg)',
                backgroundPosition: 'center 0%',
              }}
            />
            <blockquote>
              Competitive shooting pushes your mental strength and focuses on
              the limits, requiring you to remain calm and think clear in almost
              every pressure situation. Over my "career" in the shooting, I won
              many medals for my country 🇷🇸 and me, while setting a record or
              two 😎 Trained my mindset to believe that nothing is impossible
              and most importantly I met great and interesting people all over
              the world and had a great time doing it 🥳
            </blockquote>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, { getHeaderPhoto, getHeaderInfo })(About);
