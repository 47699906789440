import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import withTracker from 'hoc/withTracker';

import AllProjects from 'pages/AllProjects';
import About from 'pages/About';
import LSDesign from 'pages/LSDesign';
import CampWonderpolis from 'pages/CampWonderpolis';
import Cost2Drive from 'pages/Cost2Drive';
import FunPark from 'pages/FunPark';

import ProjectTopDecor from 'components/ProjectTopDecor';

const Content = ({ location }) => {
  return (
    <main className="projects-container">
      <ProjectTopDecor location={location} />
      <div className="container">
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            timeout={{ enter: 800, exit: 400 }}
            classNames={'toLeftTransition'}
            onEnter={() => window.scrollTo(0, 0)}
          >
            <section className="route-section">
              <Switch location={location}>
                <Route path="/" exact component={AllProjects} />
                <Route path="/about" component={About} />
                <Route path="/project/the-fun-park" component={FunPark} />
                <Route
                  path="/project/light-system-design"
                  component={LSDesign}
                />
                <Route
                  path="/project/camp-wonderpolis-2018"
                  component={CampWonderpolis}
                />
                <Route path="/project/cost-2-drive" component={Cost2Drive} />
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </main>
  );
};

export default withRouter(Content);
