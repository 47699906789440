import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { resetHeaderInfo } from 'actions';

const TopBarBack = () => {
  const dispatch = useDispatch();

  return (
    <div className="top-bar back">
      <Link
        to={'/'}
        onClick={() => dispatch(resetHeaderInfo())}
        className="active"
      >
        <i className="fa fa-angle-left" aria-hidden="true" />
        <span>Back to projects</span>
      </Link>
    </div>
  );
};

export default TopBarBack;
