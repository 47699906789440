import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import withTracker from 'hoc/withTracker';

import { getHeaderPhoto, getHeaderInfo } from 'actions';

import TopBarBack from 'components/TopBarBack';

import FunParkCover from 'static/images/fun-park-cover.jpg';

import FunParkLoginRegister from 'static/images/fun-park-login-register.gif';

import FunParkIntro1 from 'static/images/fun-park-intro-1.png';
import FunParkIntro2 from 'static/images/fun-park-intro-2.png';
import FunParkAll from 'static/images/fun-park-all.png';
import FunParkLevels from 'static/images/fun-park-levels.png';
import FunParkQuizType from 'static/images/fun-park-quiz-type.png';
import FunParkExplanation from 'static/images/fun-park-explanation.png';
import FunParkProfile from 'static/images/fun-park-profile.png';
import FunParkResults from 'static/images/fun-park-results.jpg';

class CampWonderpolis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getHeaderPhoto(FunParkCover);
    this.props.getHeaderInfo({
      headerText:
        '<em>Project,</em>\r\nThe Fun Park 2019\r\n<span>\r\n#reactnative #ios #android #wordpress #ux</span>',
      linkUrl: 'http://thefunpark.org',
      primaryColor: '247, 192, 51',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    });
  }

  render() {
    return (
      <div>
        <TopBarBack />

        <div className="projects-info fun-park">
          <section>
            <div className="desc-section app first">
              <p>
                <span>Overview</span>
                Fun Park is an open learning platform for various types of
                organizations and individuals who are interested in using
                interactive games in education.
                <br />
                <br />
                My role was to sit down with client and from start work with the
                idea of making an interactive learning platform that is open for
                everyone, managing expectations and requirements in perspective
                of technical requirements and deadline that we had to deliver.
                <br />
                <br />
                The result was customized CMS based on Wordpress platform that
                can be accessed by administrators and editors to provide
                content, add learning material and create a game(quizzes) that
                will be available on the mobile apps for android and IOS.
                <span>
                  <small>
                    Links to the app are available on the{' '}
                    <a href="http://thefunpark.org" target="_blank">
                      web version
                    </a>
                    , in header or footer, depending on the device you use
                  </small>
                </span>
              </p>
            </div>

            {/* NEW ROW */}
            <div className="desc-promo desc-section app full">
              <p>
                Login, Register and Forgot password screens <br />
                <span>
                  First impresion is very important, that is why we made it nice
                  and engaging
                </span>
              </p>
            </div>
            <div className="desc-promo no-transfrom app full">
              <img
                src={FunParkLoginRegister}
                alt="Fun Park"
                className="iphone7"
              />
            </div>

            {/* NEW ROW */}
            <div className="desc-promo desc-section app full">
              <p>
                Better understanding
                <span>
                  Take users for quick onboard tour so they get a better feeling
                  for your app
                </span>
              </p>
            </div>

            {/* NEW ROW */}
            <div className="desc-promo no-transfrom app">
              <img src={FunParkIntro1} alt="Fun Park" className="iphone7" />
            </div>
            <div className="desc-promo no-transfrom app">
              <img src={FunParkIntro2} alt="Fun Park" className="iphone7" />
            </div>

            {/* NEW ROW */}
            <div className="desc-promo desc-section app full">
              <p>
                Simple is new black
                <span>
                  Remove all noise from the user and let his subconscious do the
                  rest
                </span>
              </p>
            </div>

            {/* NEW ROW */}
            <div className="desc-promo no-transfrom app">
              <img src={FunParkAll} alt="Fun Park" className="iphone7" />
            </div>
            <div className="desc-promo no-transfrom app">
              <img src={FunParkLevels} alt="Fun Park" className="iphone7" />
            </div>

            {/* NEW ROW */}
            <div className="desc-promo desc-section app full">
              <p>
                You can make a series of different types of questions:
                <br />
                <span>
                  Multiple choice questions, Yes/No questions, etc. Add videos,
                  audios and images to your questions to amplify engagement. Use
                  badges to motivate your players
                </span>
              </p>
            </div>

            {/* NEW ROW */}
            <div className="desc-promo no-transfrom app horizontal">
              <img src={FunParkQuizType} alt="Fun Park" className="iphone7" />
            </div>

            {/* NEW ROW */}
            <div className="desc-promo desc-section app full">
              <p>
                Explain correct answer
                <span>
                  You can enable explanation modal to be displayed after each
                  question
                </span>
              </p>
            </div>

            {/* NEW ROW */}
            <div className="desc-promo no-transfrom app horizontal">
              <img
                src={FunParkExplanation}
                alt="Fun Park"
                className="iphone7"
              />
            </div>

            {/* NEW ROW */}
            <div className="desc-section desc-promo app full">
              <p>
                Track your results
                <span>
                  After completing each quiz level, you earn points and by
                  completing all levels of some topic, you earn a topic badge
                </span>
              </p>
            </div>

            {/* NEW ROW */}
            <div className="desc-promo no-transfrom app">
              <img src={FunParkProfile} alt="Fun Park" className="iphone7" />
            </div>
            <div className="desc-promo no-transfrom app">
              <img src={FunParkResults} alt="Fun Park" className="iphone7" />
            </div>

            <div className="contact-section">
              <hr style={{ backgroundColor: 'rgb(247, 192, 51)' }} />
              <h3>LET'S BUILD SOMETHING AMAZING TOGETHER</h3>
              <p>
                contact me at{' '}
                <a href="mailto:email@nemanjasmiljanic.com">
                  email@nemanjasmiljanic.com
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, { getHeaderPhoto, getHeaderInfo })(
  withTracker(CampWonderpolis)
);
