import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withTracker from 'hoc/withTracker';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getHeaderPhoto, getHeaderInfo } from 'actions';
import data from 'static/data.js';

class AllProjects extends PureComponent {
  state = {
    projectClicked: false,
    activeTag: '#projects',
  };

  componentDidMount() {
    this.props.getHeaderPhoto('');
    this.props.getHeaderInfo({
      headerText:
        "<em>Hello,</em>\r\nI'm Nemanja Smiljanic,\r\n<span>\r\npassionate web & mobile developer</span>",
      linkUrl: '',
      primaryColor: '22,22,22',
      secondaryColor: '33,33,33',
      backgroundColor: '176, 175, 175',
    });
  }

  getHeaderPhoto(image) {
    this.props.getHeaderPhoto(image);
  }

  getHeaderInfo(info) {
    this.setState({ projectClicked: true });
    this.props.getHeaderInfo(info);
  }

  renderPlaceholders = () => {
    return (
      <div className="spinner-wrapper">
        <div className="spinner"></div>
      </div>
    );
  };

  renderProjects() {
    let filteredProjects,
      projectsToRender = [];

    if (this.state.activeTag !== '') {
      if (this.state.activeTag === '#projects') {
        filteredProjects = data;
      } else {
        filteredProjects = data.filter((project) =>
          project.tags.includes(this.state.activeTag)
        );
      }

      projectsToRender = filteredProjects.map((project) => {
        return (
          <section
            style={{ backgroundImage: `url(${project.info.photo})` }}
            key={'h-' + project.id}
          >
            {project.info.external ? (
              <a
                href={project.info.external}
                target="_blank"
                className="clickable-block"
                onMouseEnter={this.getHeaderPhoto.bind(
                  this,
                  project.info.photo
                )}
                onMouseLeave={
                  this.state.projectClicked
                    ? () => {}
                    : this.getHeaderPhoto.bind(this, '')
                }
              />
            ) : (
              <Link
                to={project.info.path}
                className="clickable-block"
                onClick={this.getHeaderInfo.bind(this, {
                  headerText: '',
                  linkUrl: '',
                  primaryColor: '',
                  secondaryColor: '',
                })}
                onMouseEnter={this.getHeaderPhoto.bind(
                  this,
                  project.info.photo
                )}
                onMouseLeave={
                  this.state.projectClicked
                    ? () => {}
                    : this.getHeaderPhoto.bind(this, '')
                }
              />
            )}
            <div className="content">
              {project.info.external && (
                <div className="external-link">
                  <i class="fa fa-external-link" aria-hidden="true"></i>
                </div>
              )}
              <div className="box">
                <h3>{project.info.name}</h3>
                <ul>
                  {project.tags.map((tag, index) => (
                    <li key={project.id + '-tag' + index}>
                      <a className="station">{tag}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <a>{project.info.linkText}</a>
            </div>
          </section>
        );
      });
    }

    return projectsToRender;
  }

  handleTag(e, tag) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const fakeLoadingDelay = Math.floor(Math.random() * 500) + 300;

    setTimeout(() => {
      this.setState({ activeTag: tag, isLoading: false });
    }, fakeLoadingDelay);
  }

  render() {
    const { activeTag, isLoading } = this.state;

    return (
      <div>
        <div className="top-bar">
          <a
            onClick={(e) => this.handleTag(e, '#projects')}
            className={activeTag === '#projects' ? 'active' : ''}
          >
            #projects
          </a>
          <a
            onClick={(e) => this.handleTag(e, '#frontend')}
            className={activeTag === '#frontend' ? 'active' : ''}
          >
            #frontend
          </a>
          <a
            onClick={(e) => this.handleTag(e, '#reactjs')}
            className={activeTag === '#reactjs' ? 'active' : ''}
          >
            #reactjs
          </a>
          <a
            onClick={(e) => this.handleTag(e, '#reactnative')}
            className={activeTag === '#reactnative' ? 'active' : ''}
          >
            #reactnative
          </a>
          <a
            onClick={(e) => this.handleTag(e, '#wordpress')}
            className={activeTag === '#wordpress' ? 'active' : ''}
          >
            #wordpress
          </a>
          <a
            onClick={(e) => this.handleTag(e, '#ux')}
            className={activeTag === '#ux' ? 'active' : ''}
          >
            #ux
          </a>
        </div>

        <TransitionGroup>
          <CSSTransition
            key={activeTag}
            timeout={{ enter: 400, exit: 0 }}
            classNames={'toTopTransition'}
          >
            <div className="projects-list">
              {isLoading ? this.renderPlaceholders() : this.renderProjects()}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

export default connect(null, { getHeaderPhoto, getHeaderInfo })(
  withTracker(AllProjects)
);
