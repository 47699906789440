import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import registerServiceWorker from 'registerServiceWorker';

import 'static/style/normalize&fontAwsome.min.css';
import 'static/style/styles.min.css';

// eslint-disable-next-line no-undef
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
