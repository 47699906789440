import { HEADER_PHOTO, HEADER_INFO, RESET_HEADER_INFO } from '../actions/Types';

const INITIAL_STATE = {
  photo: '',
  info: {
    headerText:
      "<em>Hello,</em>\r\nI'm Nemanja Smiljanic,\r\n<span>\r\npassionate web & mobile developer who guarantees commitment to\r\nquality, timeframe and budget on every project.\r\n</span>",
    linkUrl: '',
    primaryColor: '22,22,22',
    secondaryColor: '33,33,33',
    backgroundColor: '176, 175, 175',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEADER_PHOTO:
      return { ...state, photo: action.payload };

    case HEADER_INFO:
      return { ...state, info: action.payload };

    case RESET_HEADER_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
};
