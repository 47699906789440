import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import withTracker from 'hoc/withTracker';

import { getHeaderPhoto, getHeaderInfo } from 'actions';

import TopBarBack from 'components/TopBarBack';
import Camp2018 from 'static/images/Camp2018.jpg';

import CampIphone1 from 'static/images/camp-iphone-1.png';
import CampIphone2 from 'static/images/camp-iphone-2.png';
import CampIphone3 from 'static/images/camp-iphone-3.png';
import CampIphone4 from 'static/images/camp-iphone-4.png';
import CampIphone5 from 'static/images/camp-iphone-5.png';
import CampIphone6 from 'static/images/camp-iphone-6.png';
import CampIphone7 from 'static/images/camp-iphone-7.png';
import CampIphone8 from 'static/images/camp-iphone-8.png';
import CampIphone9 from 'static/images/camp-iphone-9.png';
import CampIphone10 from 'static/images/camp-iphone-10.png';

import LSDWireframe from 'static/images/lsd-wireframe-croped.jpg';

class CampWonderpolis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getHeaderPhoto(Camp2018);
    this.props.getHeaderInfo({
      headerText:
        '<em>Project,</em>\r\nCamp Wonderpolis 2018\r\n<span>\r\n#reactnative #ios #android</span>',
      linkUrl: 'https://camp2018.wonderopolis.org/',
      primaryColor: '147,88,171',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    });
  }

  render() {
    return (
      <div>
        <TopBarBack />

        <div className="projects-info">
          <section>
            <div className="desc-section app first">
              <p>
                <span>Overview</span>
                Camp Wonderpolis 2018 is a free online learning destination for
                kids and their families with the goal to learn together
                throughout the summer and out-of-school time. Camp Wonderopolis
                can help build vocabulary, background knowledge, reading
                comprehension, critical thinking, and other literacy skills
                along the way.
                <br />
                <br />
                My role was to develop the mobile app for android and IOS.
                <span>
                  <small>
                    Links to the app are available on the{' '}
                    <a
                      href="https://camp2018.wonderopolis.org/"
                      target="_blank"
                    >
                      web version
                    </a>
                    , in header or footer, depending on the device you use
                  </small>
                </span>
                <br />
                The main goal of the app is to learn lessons which are separated
                by sections. App includes watching the videos and possibly
                explore more by following references. At the end of every lesson
                there is a quiz and by finishing it successfully user collects
                lesson cards that add up to his collection, awarding you with
                statuses and more avatars.
              </p>
            </div>

            <div className="desc-section app">
              <p>
                <span>
                  Login and registration screens
                  <br />
                </span>
              </p>
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone1}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone2}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section app">
              <p>
                <span>
                  Home screen with statistics and collected cards progress
                  <br />
                </span>
              </p>
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone3}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone4}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section app">
              <p>
                <span>Single lesson screen</span>
              </p>
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone5}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone10}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section app">
              <p>
                <span>Lesson quiz screen</span>
              </p>
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone6}
                alt="Light System Design"
                className="iphone7"
              />
              {/* <ProgressiveImage
                preview="/images/tiny-preview.png"
                src="/images/preview.png"
                render={(src, style) => <img src={src} style={style} />}
              /> */}
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone7}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section app">
              <p>
                <span>
                  Blog screen with filters, comments and reply to comment option
                  and edit account screen with geolocating option and
                  autocomplete for country code
                </span>
              </p>
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone8}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-promo app">
              <img
                src={CampIphone9}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <blockquote className="app">
              Special thanks to team from{' '}
              <a href="https://madebymane.com/" target="_blank">
                MANE, LLC
              </a>
            </blockquote>

            <div className="contact-section">
              <hr style={{ backgroundColor: 'rgb(147,88,171)' }} />
              <h3>LET'S BUILD SOMETHING AMAZING TOGETHER</h3>
              <p>
                contact me at{' '}
                <a href="mailto:email@nemanjasmiljanic.com">
                  email@nemanjasmiljanic.com
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, { getHeaderPhoto, getHeaderInfo })(
  withTracker(CampWonderpolis)
);
