import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';

import { BrowserRouter as Router } from 'react-router-dom';

import 'babel-polyfill';

import Header from 'components/Header';
import Content from 'components/Content';

const App = () => (
  <Provider store={store}>
    <Router>
      <Header />
      <Content />
    </Router>
  </Provider>
);

export default App;
