import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getHeaderPhoto, getHeaderInfo } from 'actions';

class Header extends PureComponent {
  state = {
    aboutMeClicked: false,
    photoToKeep: null,
  };

  handleAboutMeClick(image) {
    this.setState({ aboutMeClicked: true });
    this.props.getHeaderPhoto(image);
    this.props.getHeaderInfo({
      headerText: '',
      linkUrl: '',
      primaryColor: '224,149,89',
      secondaryColor: '238,238,238',
      backgroundColor: '255,255,255',
    });
  }

  getHeaderPhoto(image) {
    this.props.getHeaderPhoto(image);
  }

  render() {
    return (
      <header style={{ backgroundImage: `url(${this.props.header.photo})` }}>
        <div
          className="header-overlay"
          style={{
            backgroundColor: `rgba(${this.props.header.info.primaryColor}, 0.8)`,
          }}
        />
        <div className="container">
          <ul className="social-bar">
            <li>
              <a
                href="https://www.facebook.com/smiljanic.nemanja"
                target="_blank"
              >
                <i className="fa fa-facebook" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/nemanja-smiljani%C4%87-5b9423146/"
                target="_blank"
              >
                <i className="fa fa-linkedin" aria-hidden="true" />
              </a>
            </li>
            <li style={{ margin: '0 20px' }}>
              <a href="mailto:email@nemanjasmiljanic.com">
                <span
                  style={{
                    color: `rgb(${this.props.header.info.backgroundColor})`,
                  }}
                >
                  email@nemanjasmiljanic.com
                </span>
              </a>
            </li>
          </ul>
          <div className="intro-text">
            {this.props.header.info.headerText && (
              <h1
                dangerouslySetInnerHTML={{
                  __html: this.props.header.info.headerText,
                }}
              />
            )}
            {this.props.header.info.linkUrl !== '' ? (
              <a
                href={this.props.header.info.linkUrl}
                className="full-line"
                target="_blank"
              >
                Visit live
              </a>
            ) : (
              window.location.pathname !== '/about' && (
                <Link
                  to="/about"
                  onClick={this.handleAboutMeClick.bind(
                    this,
                    '/nemanja-smiljanic-portfolio.jpg'
                  )}
                >
                  Find out more about me
                </Link>
              )
            )}
          </div>
          {this.props.header.info.linkUrl !== '' && (
            <div className="bottom-bar">
              <Link
                to="/about"
                onClick={this.handleAboutMeClick.bind(
                  this,
                  '/nemanja-smiljanic-portfolio.jpg'
                )}
              >
                Find out more about me
              </Link>
            </div>
          )}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    header: state.header,
  };
};

export default connect(mapStateToProps, { getHeaderPhoto, getHeaderInfo })(
  Header
);
