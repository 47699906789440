import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withTracker from 'hoc/withTracker';

import ProgressiveImage from 'react-progressive-image-loading';

import { getHeaderPhoto, getHeaderInfo } from 'actions';

import TopBarBack from 'components/TopBarBack';
import LSDImage from 'static/images/LSD-cover.jpg';

import LSDesignHome from 'static/images/lsd-macbook.png';
import LSDPromoBackground from 'static/images/LSD-cover.jpg';
import LSDPromo1 from 'static/images/LSD-promo.jpg';
import LSDIphone1 from 'static/images/lsd-iphone-1.png';
import LSDIphone2 from 'static/images/lsd-iphone-2.png';
import LSDWireframe from 'static/images/lsd-wireframe-croped.jpg';

class LSDesign extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getHeaderPhoto, getHeaderInfo } = this.props;

    getHeaderPhoto(LSDImage);
    getHeaderInfo({
      headerText:
        '<em>Project,</em>\r\nLight System Design\r\n<span>\r\n#frontend #wordpress #ux</span>',
      linkUrl: 'http://www.lsdesign.rs',
      primaryColor: '137,22,22',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    });
  }

  render() {
    return (
      <div>
        <TopBarBack />

        <div className="projects-info">
          <section>
            <div className="desc-section first">
              <p>
                <span>Overview</span>
                Light Systems Design is a company with over 20 years of
                experience. They are a company with a great reputation in
                professional lighting for private homes, business premises and
                industrial plants.
                <br />
                <br />
                The company already had a website that was outdated and its
                structure didn't suit their current needs so they approached me
                with the request to design and create a new website based on
                WordPress CMS and migrate 1000+ products from the old website.
              </p>
            </div>

            <div className="desc-promo-background grid">
              <img src={LSDesignHome} alt="Light System Design" />
            </div>

            <div className="desc-section" style={{ paddingBottom: 40 }}>
              <p>
                My role was to create the sitemap, define pages, blocks and
                features based on their business needs and then work around that
                to create wireframe and UX so we could modify and test the
                prototype and agree to start development process on that. On top
                of new Wordpress, I made the custom, multi-language theme to be
                fully editable in admin and custom responsive front end with
                "mobile first" approach.
              </p>
            </div>

            <div className="desc-promo app">
              <img src={LSDIphone1} alt="Light System Design" />
              {/* <ProgressiveImage
                preview="/images/tiny-preview.png"
                src="/images/preview.png"
                render={(src, style) => <img src={src} style={style} />}
              /> */}
            </div>
            <div className="desc-promo app">
              <img src={LSDIphone2} alt="Light System Design" />
            </div>

            <div className="desc-section">
              <p>
                <span>UX</span>
                {/* My name is Nemanja Smiljanic, I am a passionate web & mobile
                developer who has good eye for esthetic, insights that enrich
                user experience and knowledge to make technical side happen. */}
              </p>
            </div>

            <div
              className="desc-promo-background"
              style={{ backgroundImage: `url(${LSDWireframe})` }}
            />

            <blockquote>
              Thanks to{' '}
              <a href="http://aleksandarplesko.com/" target="_blank">
                Aleksandar Plesko
              </a>{' '}
              - in-house designer of LSD for creating the recognizable visual
              language of the brand, that was easy to follow and implement in
              development.
            </blockquote>

            <div className="contact-section">
              <hr style={{ backgroundColor: 'rgb(160,48,48)' }} />
              <h3>LET'S BUILD SOMETHING AMAZING TOGETHER</h3>
              <p>
                contact me at{' '}
                <a href="mailto:email@nemanjasmiljanic.com">
                  email@nemanjasmiljanic.com
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, { getHeaderPhoto, getHeaderInfo })(
  withTracker(LSDesign)
);
