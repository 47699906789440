import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import withTracker from 'hoc/withTracker';

import { getHeaderPhoto, getHeaderInfo } from 'actions';

import TopBarBack from 'components/TopBarBack';
import Cost2DriveCover from 'static/images/cost2drive.jpg';
import Cost2Drive1 from 'static/images/cost2drive-web-1.png';
import Cost2Drive2 from 'static/images/cost2drive-web-2.png';
import Cost2Drive3 from 'static/images/cost2drive-web-3.png';
import Cost2Drive4 from 'static/images/cost2drive-web-4.png';
import Cost2Drive5 from 'static/images/cost2drive-web-5.png';
import Cost2Drive6 from 'static/images/cost2drive-web-6.png';
import Cost2driveWireframe from 'static/images/cost2drive-wireframe.jpg';

class Cost2Drive extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getHeaderPhoto(Cost2DriveCover);
    this.props.getHeaderInfo({
      headerText:
        '<em>Project,</em>\r\nCost 2 Drive,\r\n<span>\r\n#frontend #reactjs #wordpress #ux</span>',
      linkUrl: '',
      linkText: '',
      primaryColor: '43,150,195',
      secondaryColor: '33,33,33',
      backgroundColor: '255,255,255',
    });
  }

  render() {
    return (
      <div>
        <TopBarBack />

        <div className="projects-info">
          <section>
            <div className="desc-section first">
              <p>
                <span>Overview</span>
                This project is the redesign of the current web app{' '}
                <a href="https://beta.costtodrive.com/" target="_blank">
                  <small>(see here)</small>
                </a>{' '}
                which calculates a price of fuel based on the car you drive,
                your start destination, stops and final destination.
                <br />
                <br />
                <span>
                  Home screen with start, end destination input with Google's
                  places API autocomplete, and car fields with autocomplete
                  inputs
                </span>
              </p>
            </div>

            <div className="desc-promo web">
              <img
                src={Cost2Drive1}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section">
              <p>
                <span>
                  Loading screen. Random strings like "starting engine",
                  "fastening seat belt" are showing under logo until result
                  screen is ready to be displayed
                </span>
              </p>
            </div>

            <div className="desc-promo web">
              <img
                src={Cost2Drive2}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section">
              <p>
                <span>
                  Result screen with the sidebar on the left that contains
                  inputs to edit current search, options to add destination
                  stops and to add another car to compare fuel costs. On the
                  right container that contains all components with info that is
                  related to search or can be interesting to a user to make
                  another search.
                </span>
              </p>
            </div>

            <div className="desc-promo web">
              <img
                src={Cost2Drive3}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-promo web">
              <img
                src={Cost2Drive6}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section">
              <p>
                <span>
                  Result screen with route displayed on a map with pump stops
                </span>
              </p>
            </div>

            <div className="desc-promo web">
              <img
                src={Cost2Drive4}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section">
              <p>
                <span>Main navigation</span>
              </p>
            </div>

            <div className="desc-promo web">
              <img
                src={Cost2Drive5}
                alt="Light System Design"
                className="iphone7"
              />
            </div>

            <div className="desc-section">
              <p>
                <span>UX</span>
                {/* My name is Nemanja Smiljanic, I am a passionate web & mobile
                developer who has good eye for esthetic. */}
              </p>
            </div>

            <div
              className="desc-promo-background"
              style={{ backgroundImage: `url(${Cost2driveWireframe})` }}
            />

            <blockquote className="app">
              A big thanks to the{' '}
              <a href="http://purezeen.rs/" target="_blank">
                Purezeen
              </a>{' '}
              team.
            </blockquote>

            <div className="contact-section">
              <hr style={{ backgroundColor: 'rgb(43,150,195)' }} />
              <h3>LET'S BUILD SOMETHING AMAZING TOGETHER</h3>
              <p>
                contact me at{' '}
                <a href="mailto:email@nemanjasmiljanic.com">
                  email@nemanjasmiljanic.com
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default connect(null, { getHeaderPhoto, getHeaderInfo })(
  withTracker(Cost2Drive)
);
